// This is taken directly from the official gatsby-starter-default
// https://github.com/gatsbyjs/gatsby-starter-default
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { SeoSettingsQuery } from '../__generated__/types';

export type Props = {
  description: string;
  lang: string;
  meta: Array<
    | { name: string; content: any; property?: undefined }
    | { property: string; content: any; name?: undefined }
  >;
  title: string;
  imageUrl?: string;
  data: SeoSettingsQuery;
};

function BaseSEO({ title, imageUrl, data }: Props) {
  const datum = data.prismicSiteSettings?.data;
  const siteTitle = datum?.site_title ?? 'Scandi Design';
  const siteDescription =
    datum?.site_description ??
    'SCANDI DESIGN INC. Custom Homes. Cabins. Laneway Homes. 3D Renderings and Walkthroughs. We offer full scope of design services from preliminary design drawings to permit applications.';
  return (
    <Helmet title={`${title} | Scandi Design`}>
      <meta
        name="google-site-verification"
        content="U6-9RqtKtKH3lEDt1f0UV53WN3TooRvXHpWD_dHN5jA"
      />
      <meta name="og:site_name" content={siteTitle} />
      <meta
        property="og:title"
        content={title ? `${title} | ${siteTitle}` : siteTitle}
      />
      <meta name="description" content={siteDescription} />
      <meta property="og:description" content={siteDescription} />
      <meta
        property="og:image"
        content={
          imageUrl ? imageUrl : 'https://scandidesign.ca/images/favicon.png'
        }
      />
      <script
        src="https://kit.fontawesome.com/81550f52a5.js"
        crossOrigin="anonymous"
      ></script>
      <meta
        property="og:image"
        content={imageUrl ? imageUrl : './assets/images/favicon.png'}
      />
      <meta
        name="description"
        content="SCANDI DESIGN INC.\n
Custom Homes. Cabins. Laneway Homes. 3D Renderings and Walkthroughs. We offer full scope of design services from preliminary design drawings to permit applications."
      />
      <meta
        property="og:description"
        content="SCANDI DESIGN INC.\n
Custom Homes. Cabins. Laneway Homes. 3D Renderings and Walkthroughs. We offer full scope of design services from preliminary design drawings to permit applications."
      />
    </Helmet>
  );
}

function SEO(props: Omit<Props, 'data'>) {
  return (
    <StaticQuery<SeoSettingsQuery>
      query={graphql`
        query SEOSettings {
          prismicSiteSettings {
            data {
              site_description
              site_title
            }
          }
        }
      `}
      render={(data) => <BaseSEO {...props} data={data} />}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default SEO;
