import Image from 'gatsby-image';
import React from 'react';
import tw from 'twin.macro';
import {
  GatsbyImgixFluidFragment,
  ProjectBodyTwoColumnImagesFragment,
} from '../../__generated__/types';

import { ProjectSliceWrapper } from './ProjectSliceWrapper';

export const ProjectBodyTwoColumnImages: React.FC<{
  data?: ProjectBodyTwoColumnImagesFragment;
}> = ({ data }) => {
  let imageWidth = [tw`w-1/2 mr-8 lg:mr-24 `, tw`w-1/2`];
  switch (data?.primary?.first_image_width) {
    case '1/3':
      imageWidth = [tw`w-1/3 mr-8 lg:mr-24`, tw`w-2/3`];
      break;
    case '2/3':
      imageWidth = [tw`w-2/3 mr-8 lg:mr-24`, tw`w-1/3`];
      break;
    case '1/2':
      imageWidth = [tw`w-1/2 mr-8 lg:mr-24 `, tw`w-1/2`];
      break;
    default:
      imageWidth = [tw`w-full`];
      break;
  }
  return (
    <ProjectSliceWrapper css={tw`py-4px lg:py-30`} row>
      {data?.primary?.first_image?.fluid || data?.primary?.first_image ? (
        <Img
          fluid={data?.primary?.first_image?.fluid}
          fallbackImage={data.primary.first_image}
          css={imageWidth[0]}
        />
      ) : null}
      {data?.primary?.second_image ? (
        <Img
          fluid={data?.primary?.second_image?.fluid}
          fallbackImage={data.primary.second_image}
          css={imageWidth[1]}
        />
      ) : null}
    </ProjectSliceWrapper>
  );
};

export const Img: React.FC<{
  fluid?: GatsbyImgixFluidFragment | null | undefined;
  fallbackImage: any;
}> = ({ fluid, fallbackImage, ...props }) => {
  if (fluid) {
    return <Image fluid={fluid} {...props} />;
  }
  return (
    <img
      src={fallbackImage?.url}
      width={fallbackImage?.dimensions?.width}
      height={fallbackImage?.dimensions?.height}
      {...props}
    />
  );
};
