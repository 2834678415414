import React from 'react';
import tw, { styled } from 'twin.macro';

import { Footer } from './Footer';
import BaseHeader from './Header';
import SEO, { Props as SEOProps } from './SEO';

const LayoutWrap = styled.div`
  ${tw`relative`}
`;

const Header = styled(BaseHeader)`
  z-index: 2;
`;

export const Layout: React.FC<Pick<SEOProps, 'title' | 'imageUrl'>> = ({
  children,
  title,
  imageUrl,
}) => {
  return (
    <LayoutWrap>
      <SEO title={title} imageUrl={imageUrl} />
      <Header title={title} />
      <main css={tw`pt-80 sm:pt-130 lg:pt-170`}>{children}</main>
      <Footer />
    </LayoutWrap>
  );
};
