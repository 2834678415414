import React from 'react';
import tw, { styled } from 'twin.macro';

import ScandiBackground from '../assets/icons/giantScandiLogo.svg';
import { Text, TextType } from '../core-ui/Text';
import { Wrapper as BaseWrapper } from '../core-ui/Wrapper';

const Container = tw.div`w-full xl:w-8/12`;
const BodyContainer = tw.div`w-full xl:w-6/12`;
const Wrapper = tw(BaseWrapper)`pt-40 pb-24 md:pb-80`;

const BgWrap = styled.div`
  ${tw`absolute`}
  overflow: hidden;
  max-height: 100%;
  top: 0;
  @media only screen and (min-width: 640px) {
    top: -15%;
    right: -36%;
  }
  @media only screen and (min-width: 1024px) {
    max-height: 110%;
    top: -25%;
    right: -1%;
  }
`;
const ScandiBg = styled(ScandiBackground)`
  width: 454px;
  height: auto;
  @media only screen and (min-width: 640px) {
    width: 100%;
  }
`;
const Underlined = styled.u`
  white-space: pre;
`;
export const ComingSoon: React.FC = () => {
  return (
    <>
      <Wrapper>
        <Container>
          <Text type={TextType.caption} css={[tw`pb-16`]}>
            COMING SOON
          </Text>
          <div css={[tw`pb-16`]}>
            <Text type={TextType.h1}>Stay tuned.</Text>
            <Text type={TextType.h1}>We’re building something great.</Text>
          </div>
        </Container>
        <BodyContainer>
          <Text>
            In the meantime, please reach out to{' '}
            <a href="mailto:heidi@scandidesign.ca">
              <b>
                <Underlined>heidi@scandidesign.ca</Underlined>
              </b>
            </a>{' '}
            or call us at{' '}
            <a href="tel:+1604-353-7798">
              <b>
                <Underlined>+1 (604) 353 7798</Underlined>
              </b>
            </a>{' '}
            for any question or inquiries.
          </Text>
        </BodyContainer>
      </Wrapper>
      <BgWrap>
        <ScandiBg />
      </BgWrap>
    </>
  );
};
