import React, { useState } from 'react';
import tw, { css } from 'twin.macro';

import ChevronDown from '../assets/icons/chevronDown.svg';
import { Text, TextType } from '../core-ui/Text';
import { Button } from './Button';

type MobileDropdownProps = {
  labels: Array<string>;
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
};

export const MobileDropdown: React.FC<MobileDropdownProps> = ({
  labels,
  selectedIndex,
  setSelectedIndex,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div css={tw`lg:hidden`}>
      <Button
        label={labels[selectedIndex]}
        theme="light"
        fullWidth
        css={css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: start;
          ${tw`border-1`}
        `}
        animateWhenHovered={false}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <ChevronDown
          css={css`
            align-self: flex-end;
            width: 1rem;
            height: 1rem;
          `}
        />
      </Button>
      <div css={tw`relative z-5`}>
        <ul
          css={[
            tw`flex flex-col lg:flex-row lg:items-center lg:justify-center absolute lg:static w-full lg:w-auto hidden lg:flex border-1 border-black`,
            isDropdownOpen && tw`block`,
            css`
              top: 0;
              z-index: 2;
              @media only screen and (min-width: 1024px) {
                top: 0px;
              }
            `,
          ]}
        >
          {labels.map((item, index) => {
            return (
              <li
                css={css`
                  ${tw`flex lg:flex-col py-14 px-16 bg-white lg:bg-transparent items-start justify-start cursor-pointer border-b-1 last:border-b-0 border-black `}
                `}
                onClick={() => {
                  setSelectedIndex(index);
                  setIsDropdownOpen(false);
                }}
                key={item}
              >
                <Text
                  type={TextType.caption}
                  css={tw`whitespace-pre leading-24 text-center`}
                >
                  {item.toUpperCase()}
                </Text>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
