import { Link } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import tw, { css, styled } from 'twin.macro';

import { IMAGE_PLACEHOLDER } from '../../constants/config';
import { SliceWrapper } from '../../core-ui/SliceWrapper';
import { Text, TextType } from '../../core-ui/Text';
import { Wrapper } from '../../core-ui/Wrapper';
import { ProjectMetaFragment } from '../../__generated__/types';
import { Button } from '../Button';

const SlideContainer = styled(SliceWrapper)`
  ${tw`relative max-w-1440 mx-auto`}
`;

type Props = {
  data?: ProjectMetaFragment | null;
};

const Separator = styled.div`
  width: 10%;
  ${tw`bg-black mt-24 lg:mt-64 h-1 lg:h-3`}
`;

export const ProjectHeader: React.FC<Props> = ({ data: res }) => {
  if (!res?.data) {
    return null;
  }
  const data = res.data;

  return (
    <SlideContainer>
      <Wrapper
        css={tw`xl:ml-auto xl:mr-0 max-w-1360 justify-between pr-0 md:pr-0 flex-wrap pl-0 md:pl-0 lg:pl-2rem`}
        row
      >
        <div
          css={tw`w-full lg:w-5/12 xl:w-4/12 pt-24 lg:pt-56 lg:pb-8 px-24 md:px-2rem lg:px-0`}
        >
          <Link to="/projects">
            <Button
              label="See All Projects"
              tertiary
              css={[
                tw`mb-36 lg:mb-88 pl-0 items-start`,
                css`
                  text-indent: 0;
                  &:after {
                    ${tw`h-1 lg:h-2`}
                  }
                `,
              ]}
            />
          </Link>
          <Text type={TextType.h1}>{data.project_name}</Text>
          <Separator />
          <div css={tw`py-16 lg:py-64 flex flex-wrap`}>
            {data.city && data.country ? (
              <DetailItem
                label="Location"
                value={`${data.city}, ${data.country}`}
                css={tw`mr-16`}
              />
            ) : null}
            {data.floor ? (
              <DetailItem label="Floor" value={data.floor} />
            ) : null}
            {data.builder ? (
              <DetailItem
                label="Builder"
                value={data.builder}
                link={data.builder_website?.url || undefined}
              />
            ) : null}
            {data.size ? (
              <DetailItem label="Size" value={`${data.size}ft²`} />
            ) : null}

            {data.collaboration ? (
              <DetailItem label="Collaboration" value={data.collaboration} />
            ) : null}

            <DetailItem label="Status" value={data.status ?? ''} />
          </div>
        </div>
        <div css={tw`w-full lg:w-6/12 xl:w-7/12`}>
          {data.main_image?.fluid ? (
            <Image fluid={data.main_image?.fluid} css={tw`h-full`} />
          ) : (
            <img
              src={data.main_image?.url ?? IMAGE_PLACEHOLDER}
              style={{ objectFit: 'cover' }}
              css={tw`w-full h-full`}
            />
          )}
        </div>
      </Wrapper>
    </SlideContainer>
  );
};

const DetailItem = ({
  label,
  value,
  link,
}: {
  label: string;
  value?: string;
  link?: string;
}) => {
  if (!value) {
    return null;
  }
  return (
    <div css={tw`w-1/2 mb-24 lg:mb-40 pr-16 sm:pr-0`}>
      <Text type={TextType.caption} css={tw`font-medium mb-8`}>
        {label.toUpperCase()}
      </Text>
      <DetailItemValueWrapper link={link}>
        <Text
          type={TextType.caption}
          css={[tw`font-normal`, link && tw`underline`]}
        >
          {value}
        </Text>
      </DetailItemValueWrapper>
    </div>
  );
};

const DetailItemValueWrapper: React.FC<{ link?: string }> = ({
  link,
  children,
}) => {
  if (link) {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
  return <>{children}</>;
};
