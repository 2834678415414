import { Link } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import tw, { styled } from 'twin.macro';

import { IMAGE_PLACEHOLDER } from '../constants/config';
import { Text, TextType } from '../core-ui/Text';
import { GatsbyImgixFluidFragment } from '../__generated__/types';
import { Button } from './Button';

const ImageHoverState = styled.div`
  ${tw`absolute top-0 bottom-0 right-0 h-full w-full opacity-0 transition-all duration-200 ease-in-out flex justify-center`}
  background-color: rgba(0,0,0,0.4);
`;

const ProjectGridItemWrapper = styled.div`
  ${tw`flex flex-col `}
  &:hover {
    cursor: pointer;
    ${ImageHoverState} {
      ${tw`opacity-100`}
    }
  }
`;

const RootWrapper = styled.div<{ isStockPlan?: boolean }>`
  ${tw`mt-36 lg:mt-0`}
  &:nth-child(n+3) {
    ${tw`lg:mt-64`}
    ${({ isStockPlan }) => isStockPlan && tw`lg:mt-0`}
  }
  ${tw`w-full lg:w-1/2 lg:even:pl-12 lg:odd:pr-12`}
`;

type Props = {
  image?: GatsbyImgixFluidFragment | null;
  fallbackImage: string;
  projectName: string;
  subtitle: string;
  uid: string;
  onItemClick?: (uid: string) => void;
  isStockPlan?: boolean;
};

export const ProjectGridItem: React.FC<Props> = ({
  image,
  fallbackImage,
  projectName,
  subtitle,
  uid,
  onItemClick,
  isStockPlan,
}) => {
  const onClick = () => {
    onItemClick?.(uid);
  };
  return (
    <RootWrapper onClick={onClick} isStockPlan={isStockPlan}>
      <a href={isStockPlan ? undefined : `/project/${uid}`}>
        <ProjectGridItemWrapper>
          <div css={tw`relative mb-24`}>
            {image ? (
              <Image fluid={image} />
            ) : (
              <img
                src={fallbackImage ?? IMAGE_PLACEHOLDER}
                className="w-full"
              />
            )}
            {isStockPlan ? null : (
              <ImageHoverState>
                <Button
                  label="View Project"
                  theme="dark"
                  css={tw`my-auto`}
                  animateWhenHovered
                />
              </ImageHoverState>
            )}
          </div>
          {isStockPlan ? null : (
            <Text type={TextType.subtitle} className="mb-8">
              {projectName}
            </Text>
          )}
          {isStockPlan ? null : (
            <Text type={TextType.caption} className="tracking-wider">
              {subtitle}
            </Text>
          )}
        </ProjectGridItemWrapper>
      </a>
    </RootWrapper>
  );
};
