const HASHTAG_REGEX = /\#\w\w+\s?/g;

export const stripHashtag = (str: string) => {
  return str.replace(HASHTAG_REGEX, '');
};

export const trimString = (str: string, maxChars = 250) => {
  const stripped = stripHashtag(str);
  if (stripped.length <= maxChars) {
    return stripped;
  }
  return stripped.substr(0, maxChars).concat('...');
};
