import Image from 'gatsby-image';
import React from 'react';
import tw from 'twin.macro';
import { ProjectBodyFullWidthImageFragment } from '../../__generated__/types';

import { ProjectSliceWrapper } from './ProjectSliceWrapper';

type Props = {
  data?: ProjectBodyFullWidthImageFragment;
};
export const ProjectBodyFullWidthImage: React.FC<Props> = ({ data }) => {
  return (
    <ProjectSliceWrapper css={tw`mt-36 mb-4px lg:my-32`}>
      {data?.primary?.image?.fluid ? (
        <Image fluid={data?.primary?.image?.fluid} />
      ) : (
        <img src={data?.primary?.image?.url || undefined} width="100%" />
      )}
    </ProjectSliceWrapper>
  );
};
