import { graphql, PageProps } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { ComingSoon } from '../components/ComingSoon';
import { ContentRow } from '../components/ContentRow';
import { ContentRowWithVideo } from '../components/ContentRowWithVideo';
import { HowWeWork } from '../components/HowWeWork';
import { InstagramPostGallery } from '../components/InstagramPostGallery';
import { Layout } from '../components/Layout';
import { ProjectGrid } from '../components/ProjectGrid';
import ProjectSlider from '../components/ProjectSlider';
import { ServiceGrid } from '../components/ServiceGrid';
import { IS_RELEASED } from '../constants/env';
import { RichText, Text, TextType } from '../core-ui/Text';
import { Wrapper } from '../core-ui/Wrapper';
import { isLast } from '../utils/isLast';
import { PageQuery, ProjectFragment } from '../__generated__/types';
import { linkResolver } from '../core-ui/utils/linkResolver';

type Data = { data: PageQuery };

const PageTemplate = ({ data }: Data) => {
  return (
    <Layout
      title={
        data.prismicPage?.data.title?.text ??
        convertKebabCaseToTitleCase(data.prismicPage?.uid ?? '')
      }
    >
      {IS_RELEASED ? <Page data={data} /> : <ComingSoon />}
    </Layout>
  );
};

const Page = ({ data }: Data) => {
  const { prismicPage, allInstaNode, prismicSiteSettings } = data;
  const body = prismicPage?.data.body;
  const jargon = prismicSiteSettings?.[0]?.node.jargon;
  return (
    <>
      {prismicPage?.data.display_site_meta && (
        <Wrapper css={tw`pt-20 pb-0 lg:py-64`}>
          {prismicPage?.data.title ? (
            <Text type={TextType.h1} css={tw`mb-24`}>
              {prismicPage?.data.title.text}
            </Text>
          ) : null}
          {prismicPage?.data.description ? (
            <RichText field={prismicPage.data.description.richText} />
          ) : null}
        </Wrapper>
      )}

      {body?.map((item, index) => {
        let last = isLast(index, (body as Array<any>) ?? []);

        if (item.__typename === 'PrismicPageDataBodyProjectSlider') {
          return (
            <ProjectSlider
              projectSlider={item}
              key={item.__typename}
              jargon={jargon}
              last={last}
            />
          );
        }
        if (item.__typename === 'PrismicPageDataBodyCustomContent') {
          let jargons: Array<string> = jargon?.map((item) => item.text);
          return <ContentRow data={item} last={last} jargon={jargons} />;
        }
        if (item.__typename === 'PrismicPageDataBodyCustomContentVideo') {
          return <ContentRowWithVideo data={item} last={last} />;
        }
        if (item.__typename === 'PrismicPageDataBodyProjectGrid') {
          const projectGrid = item.items
            ?.map((item) => {
              if (
                item.project_item?.document?.__typename === 'PrismicProject'
              ) {
                return item.project_item.document;
              }
            })
            .filter((item) => item != null) as Array<ProjectFragment>;
          return (
            <ProjectGrid
              projects={projectGrid}
              last={last}
              //   TODO: Link to projects
              linkTo="/projects"
            />
          );
        }
        if (item.__typename === 'PrismicPageDataBodyServiceGrid') {
          return <ServiceGrid data={item} last={last} />;
        }
        if (item.__typename === 'PrismicPageDataBodyHowWeWork') {
          return <HowWeWork data={item} last={last} />;
        }
        if (item.__typename === 'PrismicPageDataBodyInstagramGallery') {
          return (
            <InstagramPostGallery
              data={allInstaNode?.nodes}
              buttonLabel={
                item.primary?.button_label ?? 'More Post on Instagram'
              }
              last={last}
              igLink={prismicSiteSettings?.data?.[0]?.node.instagram_url?.url}
            />
          );
        }
        return null;
      })}
    </>
  );
};

export const query = graphql`
  query Page($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        display_site_meta
        title {
          text
          richText
        }
        description {
          text
          richText
        }
        body {
          ...ProjectSliderFragment
          ...PageBodyCustomContent
          ...ContentRowWithVideo
          ...ProjectGrid
          ...ServiceGrid
          ...HowWeWork
          ...InstagramGallerySlice
        }
      }
    }
    prismicSiteSettings {
      _previewable
      data {
        jargon {
          text
          richText
        }
        instagram_url {
          target
          link_type
          url
        }
      }
    }
  }
`;

function convertKebabCaseToTitleCase(str: string) {
  return str
    .split('-')
    .map((i) => i[0]?.toUpperCase() + i?.substring(1)?.toLowerCase())
    .join(' ');
}

export default withPrismicPreview<Data['data'], PageProps<Data['data']>>(
  PageTemplate,
  [
    {
      repositoryName: 'scandidesign',
      linkResolver: linkResolver,
    },
  ],
);
