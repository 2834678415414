exports.linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === 'project') {
    return `/project/${doc.uid}`;
  }
  // URL for a product type
  if (doc.type === 'stock-plan') {
    return `/3d-renderings/${doc.uid}`;
  }
  // URL for a page type
  if (doc.type === 'page') {
    if (doc.uid === 'home') {
      return '/';
    }
    return `/${doc.uid}`;
  }
  // Backup for all other types
  return '/';
};
