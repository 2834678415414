import React from 'react';

import { RichText, RichTextBlock } from '../../core-ui/Text';
import { ProjectSliceWrapper } from './ProjectSliceWrapper';

type Props = {
  content?: RichTextBlock;
};

export const ProjectBodyContent = ({ content }: Props) => {
  return (
    <ProjectSliceWrapper>
      <RichText field={content} />
    </ProjectSliceWrapper>
  );
};
