import * as prismicH from '@prismicio/helpers';
import {
  Element,
  JSXFunctionSerializer,
  PrismicRichText as BaseRichText,
  PrismicRichTextProps,
} from '@prismicio/react';
import React from 'react';
import tw from 'twin.macro';
import { linkResolver } from './utils/linkResolver';

export enum TextType {
  h1 = 'h1',
  h2 = 'h2',
  title = 'title',
  subtitle = 'subtitle',
  body = 'body',
  caption = 'caption',
  span = 'span',
}

export type Props = { type?: TextType; className?: string };

const Caption = tw.p`text-3xs sm:text-xs font-medium leading-14 sm:leading-19 tracking-widest`;
const Subtitle = tw.p`text-s sm:text-2xl font-medium leading-24 sm:leading-48`;
const Title = tw.p`text-m sm:text-3xl font-medium leading-28 sm:leading-56`;
const Heading2 = tw.h2`text-l sm:text-4xl font-medium`;
const Heading1 = tw.h1`text-xl sm:text-5xl font-semibold leading-36 sm:leading-88`;
const Body = tw.p`text-2xs sm:text-1.1 font-sans leading-24 sm:leading-32`;
const Span = tw.span`text-2xs sm:text-1.1 font-sans leading-24 sm:leading-32`;

const Text: React.FC<Props> = ({ type, ...props }) => {
  switch (type) {
    case TextType.caption:
      return <Caption {...props} />;
    case TextType.subtitle:
      return <Subtitle {...props} />;
    case TextType.title:
      return <Title {...props} />;
    case TextType.h2:
      return <Heading2 {...props} />;
    case TextType.h1:
      return <Heading1 {...props} />;
    case TextType.span:
      return <Span {...props} />;
    case TextType.body:
    default:
      return <Body {...props} />;
  }
};

function RichText({ field, ...otherProps }: PrismicRichTextProps) {
  return (
    <BaseRichText
      {...otherProps}
      components={serializeRichText}
      field={field || []}
      linkResolver={linkResolver}
    />
  );
}

let serializeRichText: JSXFunctionSerializer = (
  type,
  _element,
  content,
  children,
  index,
) => {
  switch (type) {
    case Element.span:
      if (content === '') {
        return <br />;
      }
      return (
        <Text type={TextType.span} key={`${index}-span`}>
          {content}
        </Text>
      );
    case Element.paragraph:
      return (
        <Text key={`${index}-paragraph`} type={TextType.body}>
          {children}
        </Text>
      );
    case Element.heading2:
      return (
        <Text key={`${index}-heading`} type={TextType.h2}>
          {children}
        </Text>
      );
    case Element.heading1:
      return (
        <Text key={`${index}-heading`} type={TextType.h1}>
          {children}
        </Text>
      );

    default:
      return null;
  }
};
RichText.asText = prismicH.asText;

type RichTextBlock = Parameters<typeof RichText.asText>[0];
export { Text, RichText, RichTextBlock };
