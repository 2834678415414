import React from 'react';
import tw, { styled } from 'twin.macro';

export const Decorator = styled.div`
  width: 36px;
  height: 1px;
  @media only screen and (min-width: 1024px) {
    width: 48px;
    height: 3px;
  }
  ${tw`bg-black mb-16 md:mb-40`}
`;
