import tw, { styled } from 'twin.macro';

export type BgColor = 'gray' | 'black' | 'white';

export type SliceWrapperProps = { bg?: BgColor; last?: boolean };

export const SliceWrapper = styled.div<SliceWrapperProps>`
  ${({ bg }) => bg === 'black' && tw`bg-black`}
  ${({ bg }) => bg === 'gray' && tw`bg-lightGrey`}
  ${({ last }) => last && tw`pb-60p md:pb-350 lg:pb-350`}
  & {
    p,
    span {
      ${({ bg }) => bg === 'black' && tw`text-white`}
    }
  }
`;
