import React from 'react';
import tw, { css, styled } from 'twin.macro';

type BaseProps = {
  btnTheme?: 'light' | 'dark';
  tertiary?: boolean;
  animateWhenHovered?: boolean;
  fullWidth?: boolean;
  isActive?: boolean;
};

type Props = Omit<BaseProps, 'btnTheme'> & {
  label?: string;
  theme?: BaseProps['btnTheme'];
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
};

const BaseButton = styled.button<BaseProps>`
  ${tw`border border-black text-3xs md:text-xs leading-14 md:leading-19 font-semibold tracking-5 py-14 px-16 md:px-24 lg:py-24 lg:px-32 border-2 self-start transition-all duration-300 ease-in-out max-w-full`}
  ${({ fullWidth }) => (fullWidth ? tw`w-full` : tw`w-auto`)}
  ${({ btnTheme }) => btnTheme === 'dark' && tw`text-white border-white`}
  &:hover {
    ${({ animateWhenHovered, btnTheme }) => {
      return (
        animateWhenHovered &&
        css`
          ${btnTheme === 'dark' && tw`bg-white text-black`}
          ${btnTheme === 'light' && tw`bg-black text-white`}
        `
      );
    }}
  }
  ${({ tertiary }) =>
    tertiary &&
    tw`border-0 px-0 py-0 md:px-0 md:py-0 lg:px-0 lg:py-0 transition-all duration-300 ease-in-out flex flex-col items-center`}
	
  &:after {
    background-color: ${({ btnTheme }) =>
      btnTheme === 'light' ? '#000000' : '#FFFFFF'};
    content: '';
    height: 2px;
    width: 20%;
	${tw`transition-all duration-300 ease-in-out mt-8`}
	${({ tertiary, isActive }) => tertiary && isActive && tw`w-full`}
  }
  &:hover {
    &:after {
      ${tw`lg:w-full`}
    }
  }
  line-height: 19px;
  text-indent: 0.5em;
`;

export const Button: React.FC<Props> = ({
  label,
  theme = 'light',
  tertiary,
  children,
  animateWhenHovered,
  ...props
}) => {
  const defaultAnimateWhenHovered = animateWhenHovered ?? !tertiary;
  return (
    <BaseButton
      btnTheme={theme}
      tertiary={tertiary}
      animateWhenHovered={defaultAnimateWhenHovered}
      {...props}
    >
      <div css={tw`flex items-center`}>
        <div
          css={css`
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
          role="button"
        >
          {label?.toUpperCase()}
        </div>
        {children}
      </div>
    </BaseButton>
  );
};
