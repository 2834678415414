import { graphql, navigate } from 'gatsby';
import React from 'react';
import tw, { css, styled } from 'twin.macro';

import {
  BgColor,
  SliceWrapper,
  SliceWrapperProps,
} from '../core-ui/SliceWrapper';
import { Wrapper } from '../core-ui/Wrapper';
import { PageBodyCustomContentFragment } from '../__generated__/types';
import { Button } from './Button';
import { Img } from './Img';
import { Jargon } from './Jargon';
import { TextContentRow } from './TextContentRow';

// TODO: Not sure about using padding here
const ContentRowWrapper = tw(Wrapper)`py-40 lg:pt-0 flex flex-wrap`;
const ContentRowContainer = tw.div`w-full`;

type Props = {
  data: PageBodyCustomContentFragment;
  jargon: Maybe<Array<string>>;
} & Pick<SliceWrapperProps, 'last'>;

export const ContentRow: React.FC<Props> = ({ data, last, jargon }) => {
  let isTextInLeft = data.primary?.content_position === 'left';
  let contentOrder = React.useMemo(() => {
    return isTextInLeft ? ['text', 'image'] : ['image', 'text'];
  }, [isTextInLeft]);
  if (!data.primary) {
    return null;
  }
  let {
    use_decorator,
    display_jargon,
    section_title,
    content_title,
    content,
    section_image,
    action_button_position,
    section_media_size,
    content_alignment,
    action_button_title,
    link_action_button,
    sectionImageMobile,
  } = data.primary;
  let isMediaFullScreen =
    section_media_size !== 'within container' && section_image != null;

  let isActionButtonBelowText = action_button_position === 'below text content';

  let onButtonClick = () => {
    if (
      link_action_button &&
      link_action_button.document?.__typename === 'PrismicPage'
    ) {
      navigate(`/${link_action_button?.document.uid ?? ''}`);
    }
  };
  return (
    <SliceWrapper last={last}>
      <SliceWrapper bg={data.primary.background_color as BgColor}>
        <ContentRowWrapper
          css={css`
            ${
              isMediaFullScreen
                ? tw`max-w-1440 pl-0 lg:pl-0 pb-24 lg:pb-0`
                : tw`max-w-screen-xl lg:pb-130`
            }
            ${content_alignment === 'bottom' ? tw`items-end` : tw`items-start`}
			${section_image && tw`lg:pb-0 px-0`}
			${isMediaFullScreen && !isTextInLeft && tw`pt-0`}
          `}
        >
          {contentOrder.map((type) => {
            if (type === 'image') {
              return section_image ? (
                <ContentRowContainer
                  css={tw`w-full lg:w-6/12 relative`}
                  key="contentRowImage"
                >
                  <div
                    css={css`
                      ${isMediaFullScreen &&
                      tw`relative lg:absolute pr-24 lg:pr-0`}
                      top:0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                    `}
                  >
                    <Img
                      fluid={
                        sectionImageMobile?.fluid &&
                        section_image?.fluid &&
                        isMediaFullScreen
                          ? [
                              sectionImageMobile?.fluid,
                              {
                                ...section_image?.fluid,
                                media: `(min-width: 1024px)`,
                              },
                            ]
                          : section_image?.fluid
                      }
                      fallbackImage={{
                        dimensions: section_image?.dimensions,
                        url: section_image?.url,
                      }}
                      css={
                        !isMediaFullScreen
                          ? tw`hidden lg:block`
                          : tw`xl:max-h-608 lg:max-w-664`
                      }
                    />
                  </div>
                </ContentRowContainer>
              ) : null;
            }
            return (
              <ContentRowContainer
                key="contentRowText"
                css={
                  section_image
                    ? css`
                        ${tw`w-full lg:w-6/12 lg:py-60 px-24 md:px-2rem xl:px-0 lg:ml-auto`}
                        ${!isMediaFullScreen && isTextInLeft && tw`lg:pr-32`}
                        ${!isMediaFullScreen && !isTextInLeft && tw`lg:pl-32`}
						${isMediaFullScreen && !isTextInLeft && tw`xl:px-1rem`}
						${isTextInLeft ? tw`pb-24` : tw`pt-24`}
						${display_jargon && isMediaFullScreen && tw`pb-16 lg:pb-32`}
                      `
                    : tw`md:w-9/12`
                }
              >
                <TextContentRow
                  useDecorator={!!use_decorator}
                  sectionTitle={section_title}
                  contentTitle={content_title?.text}
                  content={content?.richText}
                  actionButtonTitle={
                    isActionButtonBelowText && action_button_title
                      ? action_button_title
                      : undefined
                  }
                  onButtonClick={onButtonClick}
                  imageFluid={section_image?.fluid}
                  fallbackImage={{
                    dimensions: section_image?.dimensions,
                    url: section_image?.url,
                  }}
                  showImageInMobile={!isMediaFullScreen}
                />
                <div css={tw`mt-16 lg:mt-30`}>
                  {display_jargon && isMediaFullScreen ? (
                    <Jargon jargons={jargon || []} />
                  ) : null}
                </div>
              </ContentRowContainer>
            );
          })}
          {isActionButtonBelowText || !action_button_title
            ? null
            : action_button_title != null && (
                <Wrapper css={tw`mt-80 flex justify-center`}>
                  <Button
                    label={action_button_title.toUpperCase()}
                    theme={
                      data.primary?.background_color === 'black'
                        ? 'dark'
                        : 'light'
                    }
                    onClick={onButtonClick}
                  />
                </Wrapper>
              )}
        </ContentRowWrapper>
      </SliceWrapper>
      <div css={tw`w-full lg:w-1/2 py-16 lg:py-30`} />
    </SliceWrapper>
  );
};

export const query = graphql`
  fragment PageBodyCustomContent on PrismicPageDataBodyCustomContent {
    __typename
    slice_type
    primary {
      background_color
      content {
        text
        html
        richText
      }
      content_alignment
      content_position
      content_title {
        text
        html
        richText
      }
      display_jargon
      section_title
      use_decorator
      section_media_size
      section_image {
        fluid {
          ...GatsbyImgixFluid
          aspectRatio
        }
        dimensions {
          width
          height
        }
        url
      }
      sectionImageMobile: section_image {
        fluid(maxHeight: 280, maxWidth: 296) {
          ...GatsbyImgixFluid
          aspectRatio
        }
      }
      link_action_button {
        ...LinkButtonToOtherPage
      }
      action_button_position
      action_button_title
    }
  }

  fragment LinkButtonToOtherPage on PrismicLinkType {
    __typename
    document {
      ... on PrismicPage {
        __typename
        _previewable
        id
        uid
        data {
          title {
            text
            html
            richText
          }
          description {
            text
            html
            richText
          }
        }
      }
    }
  }
`;
