import React, { Fragment } from 'react';
import tw, { styled } from 'twin.macro';

import { Text, TextType } from '../core-ui/Text';

type Props = {
  jargons: Array<string>;
};

export const Jargon: React.FC<Props> = ({ jargons }) => {
  if (jargons == null || jargons?.length === 0) {
    return null;
  }
  return (
    <JargonContainer css={tw`flex`}>
      <div
        css={tw`w-full flex items-center justify-start lg:justify-between flex-wrap`}
      >
        {jargons.map((jargon, index) => {
          let isLast = index === jargons.length - 1;
          return (
            <Fragment key={jargon}>
              <Text
                key={jargon}
                type={TextType.caption}
                css={tw`mr-12 lg:mr-0 last:mr-0 sm:text-0.83`}
              >
                <b>{jargon[0]?.toUpperCase()}</b>
                {jargon?.slice(1)}
              </Text>
              {isLast ? null : <Text css={tw`mr-12 lg:mr-0`}>•</Text>}
            </Fragment>
          );
        })}
      </div>
    </JargonContainer>
  );
};

const JargonContainer = styled.div``;
