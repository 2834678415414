import { usePagination } from '@material-ui/lab/Pagination';
import { graphql, Link, navigate } from 'gatsby';
import React, { ReactNode } from 'react';
import tw, { styled } from 'twin.macro';

import {
  Props as TextProps,
  RichText,
  Text,
  TextType,
  RichTextBlock,
} from '../core-ui/Text';
import { Wrapper } from '../core-ui/Wrapper';
import { formatLocation } from '../utils/formatLocation';
import {
  ProjectCategoryFragment,
  ProjectFragment,
  StockPlanFragment,
} from '../__generated__/types';
import { Button } from './Button';
import { MobileDropdown } from './MobileDropdown';
import { ProjectGridItem } from './ProjectGridItem';

// TODO: Change this into Slice Wrapper
const RootWrapper = styled.div<Pick<Props, 'last'>>`
  ${tw`bg-lightGrey bg-opacity-50 py-24 lg:py-70`}
  ${({ last }) => last && tw`pb-60p md:pb-350 lg:pb-350`}
`;

type FilterProps = {
  filter: Array<{ label: string; value: string }>;
  selectedFilterId: null | string;
};

type Props = {
  last: boolean;
  sectionTitle?: string | RichTextBlock;
  sectionSubtitle?: RichTextBlock;
  actionButtonTitle?: string;
  projectCategory?: Array<ProjectCategoryFragment>;
  projects?: Array<ProjectFragment>;
  stockPlans?: Array<StockPlanFragment>;
  selectedFilterId?: string;
  linkTo?: string;
  totalPage?: number;
  currentPage?: number;
  pathPrefix?: string;
  // TODO: Connect with action button link
};

export function ProjectGrid({
  projects,
  stockPlans,
  sectionTitle,
  sectionSubtitle,
  actionButtonTitle,
  projectCategory,
  selectedFilterId,
  linkTo,
  totalPage,
  currentPage,
  pathPrefix: propsPathPrefix,
  ...props
}: Props) {
  const categories =
    projectCategory?.map(({ data: { project_category_title }, uid }) => ({
      label: project_category_title ?? '',
      value: uid,
    })) ?? [];
  const { items } = usePagination({
    count: totalPage,
    hideNextButton: true,
    hidePrevButton: true,
    page: Number.isNaN(currentPage) ? 1 : currentPage,
    siblingCount: 10,
  });
  const pathPrefix =
    propsPathPrefix ??
    (stockPlans?.length ?? 0 > 1 ? '/3d-renderings' : '/projects');
  return (
    <RootWrapper {...props}>
      <Wrapper>
        {sectionTitle ? (
          <Text type={TextType.title}>
            {typeof sectionTitle === 'string'
              ? sectionTitle
              : RichText.asText(sectionTitle)}
          </Text>
        ) : null}
        {sectionTitle ? (
          <div css={tw`lg:mt-40 lg:mb-56`}>
            <RichText field={sectionSubtitle || []} />
          </div>
        ) : null}
        {categories.length > 1 ? (
          <FilterTab
            filter={categories}
            selectedFilterId={selectedFilterId ?? null}
          />
        ) : null}
        <div css={tw`flex flex-wrap md:mt-24 md:mt-56`}>
          {projects?.map(
            (
              { data: { main_image, country, city, project_name }, uid },
              index,
            ) => {
              return (
                <ProjectGridItem
                  key={`gridItem${index}`}
                  image={main_image?.fluid}
                  fallbackImage={main_image?.url || ''}
                  subtitle={formatLocation(country, city)}
                  projectName={project_name ?? ''}
                  uid={uid ?? ''}
                />
              );
            },
          )}
          {stockPlans?.map(
            ({
              data: { main_image, stock_plan_name, starting_price },
              uid,
            }) => {
              return (
                <ProjectGridItem
                  key={stock_plan_name}
                  image={main_image?.fluid}
                  fallbackImage={main_image?.url || ''}
                  subtitle={`From $${starting_price}`}
                  projectName={stock_plan_name ?? ''}
                  uid={uid ?? ''}
                  isStockPlan
                />
              );
            },
          )}
        </div>

        {actionButtonTitle ? (
          <div css={tw`mt-36 lg:mt-80 justify-center flex`}>
            <Link to={linkTo ?? '/'}>
              <Button label={actionButtonTitle} tertiary />
            </Link>
          </div>
        ) : null}
        {totalPage && totalPage > 1 ? (
          <Wrapper css={tw`mt-24`}>
            <nav>
              <ul css={tw`flex w-full justify-center`}>
                {items.map(({ page, type, selected, ...item }, index) => {
                  let children: ReactNode = null;
                  if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                    children = '…';
                  } else if (type === 'page') {
                    children = (
                      <Link
                        to={`${pathPrefix}/${page > 1 ? page : ''}`}
                        style={{ fontWeight: selected ? 'bold' : undefined }}
                        {...item}
                      >
                        <Text type={TextType.span}>{page}</Text>
                      </Link>
                    );
                  }

                  return (
                    <li key={index} css={tw`mr-24 underline last:mr-0`}>
                      {children}
                    </li>
                  );
                })}
              </ul>
            </nav>
          </Wrapper>
        ) : null}
      </Wrapper>
    </RootWrapper>
  );
}

type FilterItemProps = { active?: boolean; value: string | null };

const FilterItemText = styled(Text)<
  TextProps & Pick<FilterItemProps, 'active'>
>(({ active }) => {
  return [active && tw`font-bold`];
});

const FilterItemUnderline = styled.div<Pick<FilterItemProps, 'active'>>(
  ({ active }) => [
    `height: 2px;`,
    tw`mt-8 bg-black`,
    active ? tw`w-full` : tw`w-0`,
    `-webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;`,
  ],
);

const FilterItem: React.FC<FilterItemProps> = ({
  active,
  children,
  value,
  ...p
}) => (
  <Link to={value ? `/projects/${value}` : '/projects'} {...p}>
    <div className="flex flex-col">
      <FilterItemText type={TextType.caption} active={active}>
        {children}
      </FilterItemText>
      <FilterItemUnderline active={active} />
    </div>
  </Link>
);

const StyledFilterItem = styled(FilterItem)`
  ${tw`last:pr-0 pr-80 cursor-pointer`}
  &:hover {
    ${FilterItemUnderline} {
      width: 100%;
    }
  }
`;

const FilterTab: React.FC<FilterProps> = ({ filter, selectedFilterId }) => {
  const filterOptions = [{ label: 'All', value: null }, ...filter];
  let selectedFilterIndex = 0;
  filterOptions.forEach((item, index) => {
    if (item.value === selectedFilterId) {
      selectedFilterIndex = index;
    }
  });
  return (
    <>
      <div css={tw`mt-40 hidden lg:flex`}>
        {filterOptions.map(({ label, value }) => {
          return (
            <StyledFilterItem
              active={value === selectedFilterId}
              key={value ?? label}
              value={value}
            >
              {label.toUpperCase()}
            </StyledFilterItem>
          );
        })}
      </div>
      <div css={tw`mt-24 lg:hidden`}>
        <MobileDropdown
          labels={filterOptions.map(({ label }) => label)}
          selectedIndex={selectedFilterIndex}
          setSelectedIndex={(index) =>
            navigate(`/projects/${filterOptions[index].value || ''}`)
          }
        />
      </div>
    </>
  );
};

export const query = graphql`
  fragment ProjectGrid on PrismicPageDataBodyProjectGrid {
    __typename
    slice_type
    slice_label
    items {
      project_item {
        document {
          ... on PrismicProject {
            _previewable
            id
            ...Project
          }
        }
      }
    }
    primary {
      action_button_title
      section_title
    }
  }

  fragment ProjectCategory on PrismicProjectCategory {
    __typename
    prismicId
    uid
    data {
      project_category_title
    }
  }

  fragment Project on PrismicProject {
    __typename
    uid
    data {
      project_name
      city
      country
      main_image {
        url
        fluid(maxWidth: 738, maxHeight: 520) {
          ...GatsbyImgixFluid
        }
      }
    }
  }

  fragment StockPlan on PrismicStockPlan {
    __typename
    uid
    data {
      stock_plan_name
      starting_price
      main_image {
        url
        fluid(maxWidth: 738, maxHeight: 520) {
          ...GatsbyImgixFluid
        }
      }
    }
  }
`;
