import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import React, { Fragment, useMemo, useRef } from 'react';
import { Fade } from 'react-slideshow-image';
import tw, { styled } from 'twin.macro';

import ChevronLeft from '../assets/icons/chevronLeft.svg';
import ChevronRight from '../assets/icons/chevronRight.svg';
import { PROJECT_PAGE_SLUG } from '../constants/slugs';
import { SliceWrapper } from '../core-ui/SliceWrapper';
import { Text, TextType } from '../core-ui/Text';
import { Wrapper } from '../core-ui/Wrapper';
import { formatLocation } from '../utils/formatLocation';
import {
  GatsbyImgixFluidFragment,
  ProjectSliderFragmentFragment,
} from '../__generated__/types';

type Props = {
  projectSlider: ProjectSliderFragmentFragment;
  jargon: Maybe<Array<{ type: string; text: string }>>;
  last: boolean;
};

export default function ProjectSlider({
  projectSlider: { items, primary },
  jargon,
  last,
}: Props) {
  let jargons: Array<string> = jargon?.map((item) => item.text) || [];

  let projectItems = useMemo(
    () =>
      items
        ?.map((item) => {
          if (
            !item.project_item ||
            item.project_item?.document?.__typename !== 'PrismicProject' ||
            item.project_item.document.data.slider_main_image_web?.fluid ==
              null ||
            item.project_item.document.data.slider_main_image_mobile?.fluid ==
              null ||
            item.project_item.document.data.slider_secondary_image?.fluid ==
              null
          ) {
            return null;
          }
          return {
            name: item.project_item.document.data.project_name ?? '',
            mainImageFluid: [
              item.project_item.document.data.slider_main_image_mobile.fluid,
              {
                ...item.project_item.document.data.slider_main_image_web.fluid,
                media: `(min-width: 1024px)`,
              },
            ],
            secondaryImageFluid:
              item.project_item.document.data.slider_secondary_image.fluid,
            country: item.project_item.document.data.country,
            city: item.project_item.document.data.city,
            uid: item.project_item.document.uid,
          };
        })
        .filter((item) => item != null),
    [items],
  );
  let slideRef = useRef<Fade>(null);
  let onPressNext = () => slideRef.current?.goNext();
  let onPressBack = () => slideRef.current?.goBack();

  if (projectItems.length === 0) {
    return null;
  }

  return (
    <SlideContainer last={last}>
      <Fade autoplay={false} ref={slideRef} arrows={false}>
        {projectItems?.map((item) => {
          if (!item) {
            return null;
          }
          let {
            mainImageFluid,
            secondaryImageFluid,
            name,
            country,
            city,
            uid,
          } = item;
          return (
            <Link key={name} to={`${PROJECT_PAGE_SLUG}/${uid}`}>
              <SlideItemContainer>
                <ImageWrapper row>
                  <div css={tw`w-11/12 lg:w-8/12`}>
                    <ImageWithInsetShadow fluid={mainImageFluid} />
                  </div>
                  <SecondaryImageContainer>
                    <Image fluid={secondaryImageFluid} />
                  </SecondaryImageContainer>
                  <ProjectTitleWrapper>
                    <ImageCaptionText type={TextType.subtitle}>
                      {name}
                    </ImageCaptionText>
                    <ImageCaptionText type={TextType.caption}>
                      {formatLocation(country, city)}
                    </ImageCaptionText>
                  </ProjectTitleWrapper>
                </ImageWrapper>
              </SlideItemContainer>
            </Link>
          );
        })}
      </Fade>
      <NavigationWrapper row>
        <SlideNavButton aria-label="Previous Slide" onClick={onPressBack}>
          <ChevronLeft />
        </SlideNavButton>
        <SlideNavButton aria-label="Next Slide" onClick={onPressNext}>
          <ChevronRight />
        </SlideNavButton>
      </NavigationWrapper>
      {primary?.use_scandi_jargon && jargons?.length > 0 ? (
        <JargonContainer row>
          <div
            css={tw`w-full lg:w-7/12 flex items-center justify-start lg:justify-between py-16 lg:py-0 flex-wrap`}
          >
            {jargons.map((jargon, index) => {
              let isLast = index === jargons.length - 1;

              return (
                <Fragment key={jargon}>
                  <Text
                    key={jargon}
                    type={TextType.caption}
                    css={tw`mr-12 lg:mr-0 last:mr-0`}
                  >
                    <b>{jargon[0].toUpperCase()}</b>
                    {jargon.slice(1)}
                  </Text>
                  {isLast ? null : <Text css={tw`mr-12 lg:mr-0`}>•</Text>}
                </Fragment>
              );
            })}
          </div>
        </JargonContainer>
      ) : null}
    </SlideContainer>
  );
}

export const query = graphql`
  fragment ProjectSliderFragment on PrismicPageDataBodyProjectSlider {
    __typename
    slice_type
    slice_label
    primary {
      use_scandi_jargon
    }
    items {
      project_item {
        document {
          ... on PrismicProject {
            _previewable
            __typename
            id
            uid
            data {
              project_name
              country
              city
              slider_main_image {
                gatsbyImageData
              }
              slider_main_image_web: slider_main_image {
                fluid(maxWidth: 1138, maxHeight: 769) {
                  ...GatsbyImgixFluid
                }
              }
              slider_main_image_mobile: slider_main_image {
                fluid(maxHeight: 440, maxWidth: 284) {
                  ...GatsbyImgixFluid
                  aspectRatio
                }
              }
              slider_secondary_image {
                gatsbyImageData
                fluid(maxHeight: 810, maxWidth: 560) {
                  ...GatsbyImgixFluid
                  aspectRatio
                }
              }
            }
          }
        }
      }
    }
  }
`;

const JargonContainer = styled(Wrapper)`
  ${tw`lg:absolute`}
  bottom: 14%;
  left: 0;
  right: 0;
  z-index: 1;
`;

const SlideContainer = styled(SliceWrapper)`
  ${tw`relative max-w-1440 mx-auto`}
`;

const SlideItemContainer = tw.div`relative`;

const SlideNavButton = styled.button`
  svg {
    ${tw`w-12 md:w-16 h-auto`}
  }
  ${tw`first:mr-32`}
`;

const ImageWithInsetShadow = styled(Image)<{
  fluid: Array<GatsbyImgixFluidFragment>;
}>`
  &:after {
    -webkit-box-shadow: inset -4px -142px 95px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset -4px -142px 95px -1px rgba(0, 0, 0, 0.75);
    box-shadow: inset -4px -142px 95px -1px rgba(0, 0, 0, 0.75);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
  div:first-child {
    @media (max-width: 1023px) {
      padding-bottom: ${(props) =>
        `${100 / props?.fluid[0].aspectRatio}% !important`};
    }
    @media (min-width: 1024px) {
      padding-bottom: ${(props) =>
        `${100 / props?.fluid[1].aspectRatio}% !important`};
    }
  }
`;

const SecondaryImageContainer = styled.div`
  ${tw`absolute`}
  top: 10%;
  width: 60%;
  right: -10%;
  @media only screen and (min-width: 1024px) {
    right: 2rem;
    width: 35%;
  }
`;
const ImageWrapper = styled(Wrapper)`
  ${tw`relative pl-0 md:ml-0 md:pl-0 max-w-1360`}
  @media only screen and (min-width: 1024px) {
    margin-bottom: 15%;
  }
`;

const ProjectTitleWrapper = styled(Wrapper)`
  ${tw`absolute mb-24 lg:mb-0`}
  left: 0;
  bottom: 8%;
  @media only screen and (min-width: 1024px) {
    bottom: 10%;
    left: 6%;
  }
`;

const NavigationWrapper = styled(Wrapper)`
  ${tw`absolute mb-24 lg:mb-0`}
  z-index: 1;
  left: 0;
  height: fit-content;
  bottom: 15%;
  @media only screen and (min-width: 640px) {
    bottom: 13%;
  }
  @media only screen and (min-width: 768px) {
    bottom: 8%;
  }
  @media only screen and (min-width: 1024px) {
    top: 62%;
    right: 44%;
    left: auto;
    height: initial;
    bottom: unset;
  }
`;

const ImageCaptionText = tw(Text)`text-white`;
