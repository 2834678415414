/*
  Import Tailwind's base styles into the browser.
*/
import './src/styles/globals.css';
import './src/styles/hamburgers.css';
import 'react-slideshow-image/dist/styles.css';
import 'react-modal-video/css/modal-video.min.css';
import { linkResolver } from './src/core-ui/utils/linkResolver';
import { componentResolver } from './src/core-ui/utils/componentResolver';
import Page from './src/templates/Page';

import React from 'react';
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: 'scandidesign',
        linkResolver,
        componentResolver,
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
