import { graphql, Link } from 'gatsby';

import React, { useEffect, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import tw, { css, styled } from 'twin.macro';
import { InstagramGallery } from 'instagram-gallery';

import Arrow from '../assets/icons/arrow.svg';
import { SliceWrapper } from '../core-ui/SliceWrapper';
import { Text, TextType } from '../core-ui/Text';
import { trimString } from '../utils/stripHashtag';
import { InstagramPostFragment } from '../__generated__/types';
import { Button } from './Button';

type Props = {
  data: Array<InstagramPostFragment>;
  igLink: string;
  last?: boolean;
  buttonLabel?: string;
};

const ArrowButton = styled.button`
  ${tw`py-16 lg:py-36 px-8 lg:px-20 relative`}
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
`;

const LeftArrowIcon = styled(Arrow)`
  width: 10px;
  @media only screen and (min-width: 1024px) {
    width: 26px;
  }
  path {
    stroke: #fff;
  }
`;

const ImageCaption = styled.div`
  ${tw`absolute left-0 top-0 right-0 bottom-0 transition-all duration-300 ease-in-out px-32 py-40 flex items-end`}
  background-color: rgba(0,0,0,0.8);
  opacity: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Wrapper = styled.div`
  ${tw`relative`}
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const InstagramPostGallery: React.FC<Props> = ({
  data,
  igLink,
  last,
  buttonLabel,
}) => {
  const container = useRef<ScrollContainer>(null);
  const [horizontalOffset, setHorizontalOffset] = useState(0);
  const [loading, setLoading] = useState<Boolean>(true);
  const [error, setError] = useState<Boolean>(false);
  const [instagramData, setInstagramData] = useState<any>(null);
  const [usePagination, setUsePagination] = useState<boolean>(false);
  const [paginationNextUrl, setPaginationNextUrl] = useState<string>('');
  const [paginationPrevUrl, setPaginationPrevUrl] = useState<string>('');
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const fetchInstagramData = (url: string) => {
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log({ data });
        if (data.hasOwnProperty('error')) {
          setLoading(false);
          setError(true);
        } else {
          setInstagramData(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setError(true);
        setLoading(false);
      });
  };
  const count = 20;
  const accessToken = process.env.GATSBY_IG_ACCESS_TOKEN;
  useEffect(() => {
    const url = `https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,media_url,caption&limit=${count}&access_token=${accessToken}`;
    fetchInstagramData(url);
  }, []);

  const onPrevClick = () => {
    container.current?.getElement().scrollTo({
      left:
        horizontalOffset - (imageContainerRef.current?.offsetWidth ?? 0) * 2,
      behavior: 'smooth',
    });
  };
  const onNextClick = () => {
    container.current?.getElement().scrollTo({
      left:
        horizontalOffset + (imageContainerRef.current?.offsetWidth ?? 0) * 2,
      behavior: 'smooth',
    });
  };

  if (loading) {
    return (
      <SliceWrapper css={tw`relative`} last={last}>
        <ScrollContainer
          css={css`
            ${tw`flex`}
          `}
          ref={container}
          onEndScroll={() => {
            setHorizontalOffset(
              container.current?.getElement().scrollLeft || 0,
            );
          }}
          vertical={false}
        >
          {Array.from(Array(20).keys()).map(() => (
            <div
              css={css`
                ${tw` w-1/2 md:w-4/12 lg:w-1/5`}
                min-width: 50%;
                @media only screen and (min-width: 768px) {
                  min-width: 33.333333%;
                }
                @media only screen and (min-width: 1024px) {
                  min-width: 20%;
                }
                cursor: pointer;
              `}
            >
              <Wrapper ref={imageContainerRef}>
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                  }}
                >
                  <div
                    css={css`
                      flex: 1;
                      align-items: center;
                      display: flex;
                      justify-content: center;
                      border-right-width: 1px;
                      border-top-width: 1px;
                      border-bottom-width: 1px;
                      border-color: black;
                    `}
                  >
                    <i className="fas fa-spinner fa-spin fa-2xl"></i>
                  </div>
                </div>
              </Wrapper>
            </div>
          ))}
        </ScrollContainer>
      </SliceWrapper>
    );
  }
  return (
    <SliceWrapper css={tw`relative`} last={last}>
      <div css={tw`relative`}>
        <ScrollContainer
          css={css`
            ${tw`flex`}
          `}
          ref={container}
          onEndScroll={() => {
            setHorizontalOffset(
              container.current?.getElement().scrollLeft || 0,
            );
          }}
          vertical={false}
        >
          {instagramData?.data?.map((item) => {
            return (
              <a
                href={item.permalink}
                key={item.id}
                target="_blank"
                rel="noopener noreferrer"
                css={css`
                  ${tw` w-1/2 md:w-4/12 lg:w-1/5`}
                  min-width: 50%;
                  @media only screen and (min-width: 768px) {
                    min-width: 33.333333%;
                  }
                  @media only screen and (min-width: 1024px) {
                    min-width: 20%;
                  }
                  cursor: pointer;
                  &:hover {
                    @media only screen and (min-width: 1024px) {
                      ${ImageCaption} {
                        opacity: 1;
                      }
                    }
                  }
                `}
              >
                <Wrapper ref={imageContainerRef}>
                  <div
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {item.media_url ? (
                      <Image
                        key={item.id}
                        src={item.media_url}
                        alt={item.caption}
                      />
                    ) : null}
                    <ImageCaption>
                      <Text
                        type={TextType.caption}
                        css={css`
                          ${tw`text-white`}
                          letter-spacing: unset;
                        `}
                      >
                        {trimString(item.caption ?? '')}
                      </Text>
                    </ImageCaption>
                  </div>
                </Wrapper>
              </a>
            );
          })}
        </ScrollContainer>
        <div
          css={css`
            ${tw`absolute flex items-center justify-center h-full`}
            top: 0;
          `}
        >
          <ArrowButton
            onClick={onPrevClick}
            css={css`
              left: 0;
            `}
          >
            <LeftArrowIcon />
          </ArrowButton>
        </div>
        <div
          css={css`
            ${tw`absolute flex items-center justify-center h-full`}
            top: 0;
            right: 0;
          `}
        >
          <ArrowButton onClick={onNextClick}>
            <LeftArrowIcon
              css={css`
                transform: rotate(180deg);
              `}
            />
          </ArrowButton>
        </div>
      </div>

      <div css={tw`mt-28 lg:mt-64 flex justify-center w-full`}>
        <Link to={igLink} css={tw`w-10/12 lg:w-auto `}>
          <Button
            label={buttonLabel?.toUpperCase() ?? 'MORE POST ON INSTAGRAM'}
          />
        </Link>
      </div>
    </SliceWrapper>
  );
};

export const query = graphql`
  #   fragment InstagramPost on InstaNode {
  #     id
  #     caption
  #     localFile {
  #       childImageSharp {
  #         fluid {
  #           ...GatsbyImageSharpFluid
  #         }
  #       }
  #     }
  #   }

  fragment InstagramGallerySlice on PrismicPageDataBodyInstagramGallery {
    __typename
    primary {
      button_label
    }
  }
`;
