import React from 'react';
import tw from 'twin.macro';

import { Decorator } from '../core-ui/Decorator';
import { RichText, Text, TextType, RichTextBlock } from '../core-ui/Text';
import { GatsbyImgixFluidFragment } from '../__generated__/types';
import { Button } from './Button';
import { Img } from './Img';

type Props = {
  useDecorator?: boolean;
  sectionTitle?: string | null;
  contentTitle?: string | null;
  content?: RichTextBlock;
  actionButtonTitle?: string;
  actionButtonTheme?: 'dark' | 'light';
  onButtonClick?: () => void;
  showImageInMobile?: boolean;
  imageFluid?: GatsbyImgixFluidFragment | null;
  fallbackImage?: {
    dimensions:
      | {
          width: number;
          height: number;
        }
      | null
      | undefined;
    url: string | null | undefined;
  } | null;
};

export const TextContentRow: React.FC<Props> = ({
  useDecorator,
  sectionTitle,
  contentTitle,
  content,
  actionButtonTitle,
  actionButtonTheme,
  onButtonClick,
  showImageInMobile,
  imageFluid,
  fallbackImage,
}) => {
  return (
    <div>
      {useDecorator ? <Decorator /> : null}
      {sectionTitle ? (
        <Text type={TextType.caption} css={tw`pb-8 md:pb-16`}>
          {sectionTitle.toUpperCase()}
        </Text>
      ) : null}
      {contentTitle ? (
        <Text type={TextType.title} css={tw`pb-16`}>
          {contentTitle}
        </Text>
      ) : null}
      {showImageInMobile && imageFluid && fallbackImage ? (
        <div css={tw`lg:hidden w-full pb-24`}>
          <Img fluid={imageFluid} fallbackImage={fallbackImage} />
        </div>
      ) : null}
      <RichText field={content} />
      {/* todo: connect action button link */}
      {actionButtonTitle ? (
        <div css={tw`mt-24 md:mt-40 flex justify-center lg:justify-start`}>
          <Button
            label={actionButtonTitle.toUpperCase()}
            theme={actionButtonTheme}
            onClick={onButtonClick}
          />
        </div>
      ) : null}
    </div>
  );
};
