import tw, { styled } from 'twin.macro';

type Props = {
  row?: boolean;
};

export const Wrapper = styled.div<Props>(({ row }) => [
  tw`max-w-screen-xl px-24 md:px-2rem xl:mx-auto`,
	row && tw`flex`,
]);
