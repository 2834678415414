import React from 'react';
import tw, { styled } from 'twin.macro';

import { RichText, Text, TextType } from '../../core-ui/Text';
import { ProjectTestimonyFragment } from '../../__generated__/types';
import { ProjectSliceWrapper } from './ProjectSliceWrapper';

const Separator = styled.div`
  ${tw`bg-black h-1 lg:h-3 mx-auto w-24 lg:w-48`}
`;

export const ProjectTestimony: React.FC<{
  data?: ProjectTestimonyFragment;
}> = ({ data }) => {
  if (
    data?.primary?.testimony_message == null &&
    data?.primary?.endorser_name == null
  ) {
    return null;
  }
  return (
    <ProjectSliceWrapper css={tw`mt-24 lg:mt-88`}>
      <Text css={tw`text-center`}>
        “{data?.primary?.testimony_message?.text}”
      </Text>
      <Text type={TextType.caption} css={tw`mt-24 lg:mt-40 text-center`}>
        {data?.primary?.endorser_name}
      </Text>
      <Separator css={tw`mt-36 lg:mt-40`} />
    </ProjectSliceWrapper>
  );
};
