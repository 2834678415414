import { graphql } from 'gatsby';
import React from 'react';
import tw, { css, styled } from 'twin.macro';

import { SliceWrapper, SliceWrapperProps } from '../core-ui/SliceWrapper';
import { RichText, Text, TextType } from '../core-ui/Text';
import { Wrapper } from '../core-ui/Wrapper';
import { ServiceGridFragment } from '../__generated__/types';

type Props = Pick<SliceWrapperProps, 'last'> & {
  data: ServiceGridFragment;
};

const ServiceGridWrapper = styled(SliceWrapper)`
  ${tw`pt-56 pb-60 lg:pb-120`}
`;

const ServiceGridContainer = styled.div`
  ${tw`flex flex-wrap`}
`;

const ServiceGridItem = styled.div`
  ${tw`flex flex-col w-full lg:w-1/2 xl:w-1/3`}
  &:not(:first-child) {
    ${tw`pt-36`}
  }
  @media only screen and (min-width: 1024px) {
    &:nth-child(2) {
      ${tw`pt-0`}
    }
  }
  @media only screen and (min-width: 1240px) {
    &:nth-child(3) {
      ${tw`pt-0`}
    }
    &:nth-child(n + 4) {
      ${tw`pt-60`}
    }
  }
`;

export const ServiceGrid: React.FC<Props> = ({ last, data }) => {
  return (
    <ServiceGridWrapper last={last}>
      <Wrapper>
        <ServiceGridContainer>
          {data.items?.map(
            ({ service_icon, service_title, service_description }) => {
              return (
                <ServiceGridItem key={service_title}>
                  {service_icon?.dimensions && service_icon.url ? (
                    <img
                      src={service_icon?.url}
                      css={css`
					  ${tw`max-h-36 lg:max-h-56 mb-16`}
					  width: ${
              (service_icon?.dimensions.width /
                service_icon?.dimensions.height) *
              36
            }px;
                      @media only screen and (min-width: 1024px) {
                        width: ${
                          (service_icon.dimensions.width /
                            service_icon.dimensions.height) *
                          56
                        }px;
                      }
                    `}
                    />
                  ) : null}
                  {service_title ? (
                    <Text
                      type={TextType.subtitle}
                      css={tw`pb-24 lg:pr-20 sm:text-1.7 sm:leading-36`}
                    >
                      {service_title}
                    </Text>
                  ) : null}
                  {service_description ? (
                    <div
                      css={css`
                        a {
                          ${tw`underline`}
                        }
                        ${tw`lg:pr-40`}
                      `}
                    >
                      <RichText field={service_description.richText} />
                    </div>
                  ) : null}
                </ServiceGridItem>
              );
            },
          )}
        </ServiceGridContainer>
      </Wrapper>
    </ServiceGridWrapper>
  );
};

export const query = graphql`
  fragment ServiceGrid on PrismicPageDataBodyServiceGrid {
    __typename
    slice_type
    slice_label
    items {
      service_title
      service_description {
        text
        richText
      }
      service_icon {
        url
        dimensions {
          width
          height
        }
      }
    }
  }
`;
