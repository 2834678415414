import Image, { FluidObject } from 'gatsby-image';
import React from 'react';
import { GatsbyImgixFluidFragment } from '../__generated__/types';

export const Img: React.FC<{
  fluid?: GatsbyImgixFluidFragment | Array<FluidObject> | null;
  fallbackImage?: {
    dimensions?: {
      width: number;
      height: number;
    } | null;
    url?: string | null;
  };
}> = ({ fluid, fallbackImage, ...props }) => {
  return fluid ? (
    <Image fluid={fluid} {...props} />
  ) : (
    <img src={fallbackImage?.url || ''} width="100%" {...props} />
  );
};
